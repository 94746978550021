(function ($) {
    let $fn = $(".comp_product_detail");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider-current]"),
            $slider_thumbs = $fn.find("[data-slider-thumbs]"),
            $slider_count = $slider.children().length;

        $slider.lightGallery({
            thumbnail: true,
            selector: ".elm_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false
        });

        if($slider_count > 1){
            $slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                asNavFor: $slider_thumbs
            });

            $slider_thumbs.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                asNavFor: $slider,
                prevArrow: "<i class=\"icon icon--chevron-left\" data-arrow=\"prev\"></i>",
                nextArrow: "<i class=\"icon icon--chevron-right\" data-arrow=\"next\"></i>",
                focusOnSelect: true
            });
        }
        if($slider_count < 5){
            $slider_thumbs.addClass("mod--no-arrows");
        }
    }
})(jQuery);