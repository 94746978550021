(function ($) {
    let $fn = $(".comp_visual_references");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]"),
            $prevArrow = $fn.find("[data-arrow=\"prev\"]"),
            $nextArrow = $fn.find("[data-arrow=\"next\"]"),
            $autoplay = ($slider.data("slider") > 0),
            $speed = $slider.data("slider");

        if($slider.children().length > 1) {
            $slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                autoplay: $autoplay,
                autoplaySpeed: $speed,
                prevArrow: $prevArrow,
                nextArrow: $nextArrow,
                fade: true
            })
        }
        else {
            $fn.find(".wrp_arrows").remove();
        }
    }
})(jQuery);