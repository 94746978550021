//____ ANCHOR LINKS ANIMATIONS ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function fn_nl_lib_anchor() {
        var $hash = window.location.hash;

    $.fn.anchor_anim = function (duration) {
        var $elm = $(this),
        $offset = 0;
        if ($elm.is("[data-anchor-offset]") && $(window).width() > 960) {
            if (isNaN($elm.data("anchor-offset"))) {
                $offset = $($elm.data("anchor-offset")).innerHeight();
            } else {
                $offset = $elm.data("anchor-offset");
            }
        }
        $('html, body').animate({
            scrollTop: $elm.offset().top - $offset
        }, duration);
    };

    $(document).on("click", "[data-anchor]", function(e) {
        var $id = $(this).attr("href"),
            $anchor_elm = $("[data-anchor-elm="+$id.replace('#','')+"]");

        if ($(this).filter("[data-anchor-href]").length) {
            $id = $(this).data("anchor-href");
        }
        if ($anchor_elm.length) {
            e.preventDefault();

            if (!($(this).is("[data-anchor-mobile]") && $(window).width() > 960)) {
                $anchor_elm.anchor_anim(500);
                if($(this).data("anchor") === "hash") {
                    window.location.hash = $id;
                }
            }
        }
    });

    $("[data-anchor-elm]").each(function(){
        var $elm = $(this);
        if($hash && $elm.data("anchor-elm") === $hash.replace('#','')) {
            $elm.anchor_anim(0);
        }
    });
}